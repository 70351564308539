import { FieldErrors, UseFormRegister } from "react-hook-form";
import {
  Box,
  Divider,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { AccountingNoteDTO } from "../../../../domain/AccountingNote/AccountingNoteDTO";
import React from "react";
import { AccountingNoteTableDTO } from "../../../../domain/AccountingNote/AccountingNoteTableDTO";

const DocumentSnapshot: React.FC<{
  accountingNote: AccountingNoteDTO,
  isEditable: boolean,
  errors: FieldErrors,
  onNoteChanged: (
    key: keyof AccountingNoteDTO,
    value: any
  ) => void,
  register: UseFormRegister<AccountingNoteDTO>
}> = (props) => {
  const { accountingNote, errors, onNoteChanged, register } = props;
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#EFF1F8",
          padding: "20px",
          border: "1px solid gray",
          borderRadius: "20px 20px 0 0",
        }}
      >
        <Grid item xs={12} md={8} sx={{ display: "flex", flexDirection: "column" }}>
          <TextField size="small" label="Vendor Name" variant="standard"
            value={accountingNote.vendorName || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.vendorName && errors.vendorName?.message !== undefined}
            helperText={errors.vendorName ? errors.vendorName?.message?.toString() : null}
            {...register("vendorName", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('vendorName', event.target.value);
            }} />
          <TextField size="small" label="Vendor Address" variant="standard"
            value={accountingNote.vendorAddress || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.vendorAddress && errors.vendorAddress?.message !== undefined}
            helperText={errors.vendorAddress ? errors.vendorAddress?.message?.toString() : null}
            {...register("vendorAddress", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('vendorAddress', event.target.value);
            }} />
          <TextField size="small" label="Vendor Address Recipient" variant="standard"
            value={accountingNote.vendorAddressRecipient || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.vendorAddressRecipient && errors.vendorAddressRecipient?.message !== undefined}
            helperText={errors.vendorAddressRecipient ? errors.vendorAddressRecipient?.message?.toString() : null}
            {...register("vendorAddressRecipient", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('vendorAddressRecipient', event.target.value);
            }} />
          <TextField size="small" label="Vendor Tax Id" variant="standard"
            value={accountingNote.vendorTaxId || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.vendorTaxId && errors.vendorTaxId?.message !== undefined}
            helperText={errors.vendorTaxId ? errors.vendorTaxId?.message?.toString() : null}
            {...register("vendorTaxId", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('vendorTaxId', event.target.value);
            }} />
          <TextField size="small" label="Invoice Id" variant="standard"
            value={accountingNote.invoiceId || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.invoiceId && errors.invoiceId?.message !== undefined}
            helperText={errors.invoiceId ? errors.invoiceId?.message?.toString() : null}
            {...register("invoiceId", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('invoiceId', event.target.value);
            }} />
          <TextField size="small" label="Invoice Date" variant="standard"
            value={accountingNote.invoiceDate || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.invoiceDate && errors.invoiceDate?.message !== undefined}
            helperText={errors.invoiceDate ? errors.invoiceDate?.message?.toString() : null}
            {...register("invoiceDate", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('invoiceDate', event.target.value);
            }} />
          <TextField size="small" label="Due Date" variant="standard"
            value={accountingNote.dueDate || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.dueDate && errors.dueDate?.message !== undefined}
            helperText={errors.dueDate ? errors.dueDate?.message?.toString() : null}
            {...register("dueDate", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('dueDate', event.target.value);
            }} />
          <TextField size="small" label="Currency Code" variant="standard"
            value={accountingNote.currencyCode || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.currencyCode && errors.currencyCode?.message !== undefined}
            helperText={errors.currencyCode ? errors.currencyCode?.message?.toString() : null}
            {...register("currencyCode", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('currencyCode', event.target.value);
            }} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "2px solid #c4c4c2",
            paddingLeft: "15px",
          }}
        >
          <TextField size="small" label="Customer Name" variant="standard"
            value={accountingNote.customerName || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.customerName && errors.customerName?.message !== undefined}
            helperText={errors.customerName ? errors.customerName?.message?.toString() : null}
            {...register("customerName", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('customerName', event.target.value);
            }} />
          <TextField size="small" label="Customer ID" variant="standard"
            value={accountingNote.customerId || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.customerId && errors.customerId?.message !== undefined}
            helperText={errors.customerId ? errors.customerId?.message as string : null}
            {...register("customerId", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('customerId', event.target.value);
            }} />
          <TextField size="small" label="Customer Address" variant="standard"
            value={accountingNote.customerAddress || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.customerAddress && errors.customerAddress?.message !== undefined}
            helperText={errors.customerAddress ? errors.customerAddress?.message?.toString() : null}
            {...register("customerAddress", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('customerAddress', event.target.value);
            }} />
          <TextField size="small" label="Customer Address Recipient" variant="standard"
            value={accountingNote.customerAddressRecipient || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.customerAddressRecipient && errors.customerAddressRecipient?.message !== undefined}
            helperText={errors.customerAddressRecipient ? errors.customerAddressRecipient?.message?.toString() : null}
            {...register("customerAddressRecipient", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('customerAddressRecipient', event.target.value);
            }} />
          <TextField size="small" label="Customer Tax Id" variant="standard"
            value={accountingNote.customerTaxId || ''}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.customerTaxId && errors.customerTaxId?.message !== undefined}
            helperText={errors.customerTaxId ? errors.customerTaxId?.message?.toString() : null}
            {...register("customerTaxId", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('customerTaxId', event.target.value);
            }} />
        </Grid>
      </Grid>

      <Box
        sx={{
          borderLeft: "1px solid gray",
          borderRight: "1px solid gray",
        }}
      >
        {accountingNote.tables?.map((table: AccountingNoteTableDTO, index: number) => (
          <Box key={index}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 700 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableBodyRows table={table} />
                </TableBody>
              </Table>
            </TableContainer>
            {index < accountingNote.tables.length - 1 && <Divider />}
          </Box>
        ))}
      </Box>

      <Grid
        container
        sx={{
          backgroundColor: "#EFF1F8",
          padding: "20px",
          border: "1px solid gray",
          borderRadius: "0 0 20px 20px",
        }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: "flex", flexDirection: "column" }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "2px solid #c4c4c2",
            paddingLeft: "15px",
          }}
        >
          <TextField size="small" label="Subtotal" variant="outlined"
            value={accountingNote.subTotal || '0'}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.subTotal && errors.subTotal?.message !== undefined}
            helperText={errors.subTotal ? errors.subTotal?.message?.toString() : null}
            {...register("subTotal", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('subTotal', event.target.value);
            }} />
          <TextField size="small" label="VAT" variant="outlined"
            value={accountingNote.totalVat || '0'}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.totalVat && errors.totalVat?.message !== undefined}
            helperText={errors.totalVat ? errors.totalVat?.message?.toString() : null}
            {...register("totalVat", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('totalVat', event.target.value);
            }} />
          <TextField size="small" label="Total" variant="outlined"
            value={accountingNote.invoiceTotal || '0'}
            sx={{ margin: "10px 0 0 0", width: 380, maxWidth: '100%' }}
            error={errors.invoiceTotal && errors.invoiceTotal?.message !== undefined}
            helperText={errors.invoiceTotal ? errors.invoiceTotal?.message?.toString() : null}
            {...register("invoiceTotal", { disabled : !props.isEditable })}
            onChange={(event) => {
              event.stopPropagation();
              onNoteChanged('invoiceTotal', event.target.value);
            }} />
        </Grid>
      </Grid>
    </>
  );
};

const TableBodyRows: React.FC<{ table: AccountingNoteTableDTO }> = (props) => {
  const rowsDictionary = _.groupBy(
    props.table.cells.filter((item) => item.rowIndex !== 0),
    (row) => row.rowIndex
  );

  const rows = Object.entries(rowsDictionary).map(([key, value]) => (
    <StyledTableRow key={key}>
      {value.map((cell, index) => (
        <StyledTableCell key={index} align={index === 0 ? "left" : "right"}>
          {cell.content}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));

  return (<>{rows}</>);
};

export { DocumentSnapshot };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
