import DatePicker from "react-datepicker";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Theme,
  Typography,
  tabsClasses,
} from "@mui/material";
import { IDocumentFilter } from "..";
import { DocumentTypeDTO } from "../../../domain/Document/DocumentType";
import DocumentWorkflowStateDTO from "../../../domain/Document/DocumentWorkflowStateDTO";
import { DocumentsActions } from "./DocumentActions";
import DocumentWorkflowStateTransitionsDTO from "../../../domain/Document/DocumentWorkflowStateTransitionDTO";
import { DocumentActions } from "./index.types";
import { ThemeProvider } from "@emotion/react";
import myTheme from "../../../helpers/themeHelper";
import { DocumentWorkflowStateIds } from "../../../domain/Document/DocumentWorkflowStatesIds";
import '../index.css'
import { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";

const DatePickerElement: React.FC<{
  date: Date;
  label: string;
  onDateChange: (date: Date) => void;

}> = (props) => (
  <Box sx={{ width: "120px" }}>
    <Typography
      fontSize={"12px"}
      color={myTheme.palette.primary.main}
      sx={{ position: "relative", ml: "5px", mt: "-17px" }}
    >
      {props.label}
    </Typography>
    <DatePicker selected={props.date} onChange={props.onDateChange} />
  </Box>
);

const checkTabToDisplay = (
  selectedState: DocumentWorkflowStateDTO | null,
  currentlySelectedState: DocumentWorkflowStateDTO | null
) => {
  return true;
  // Check if the selected state is approved
  if (currentlySelectedState?.id == DocumentWorkflowStateIds.Scrapped ||
    currentlySelectedState?.id == DocumentWorkflowStateIds.Archived ||
    currentlySelectedState?.id == DocumentWorkflowStateIds.Approved ||
    currentlySelectedState?.id == DocumentWorkflowStateIds.Modified ||
    currentlySelectedState?.id == DocumentWorkflowStateIds.Failed ||
    currentlySelectedState?.id == DocumentWorkflowStateIds.LowConfidence
  ) {
    if (selectedState?.id == DocumentWorkflowStateIds.HighConfidence ||
      selectedState?.id == DocumentWorkflowStateIds.Processing ||
      selectedState?.id == DocumentWorkflowStateIds.Uploaded

    ) {
      return false;
    }

  }
  return true;
  console.log(selectedState?.name);

  if (selectedState) return true;
};

const DocumentStateTabElements: React.FC<{
  documentStates: DocumentWorkflowStateDTO[];
  currentlySelectedState: DocumentWorkflowStateDTO | null;
  onDocumentStateChange: (state: DocumentWorkflowStateDTO) => void;
  toBeDisplayed: boolean;
  docsInStateNumber?: number[];
}> = (props) => (
  <>
    {props.documentStates.length > 0 && (
      <Tabs
        value={props.currentlySelectedState}
        onChange={(_, value) => {
          //console.log(`DocumentStateTabElements: changed doc state to : ${value.name}`);
          props.onDocumentStateChange(value);
        }}
        variant="scrollable"
        textColor="primary"
        scrollButtons
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          }
        }}

      >
        {props.documentStates.map((state, index) =>
          checkTabToDisplay(state, props.currentlySelectedState) ? (
            <Tab key={index} value={state}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{state.name}</span>
                <span
                  style={{
                    color: 'black',
                    border: '2px solid #20BAD1',
                    borderRadius: '20%',
                    padding: '2px 4px',
                    marginLeft: '8px', // Adds space between the name and the number
                    display: 'inline-block',
                  }}
                >
                  {props.docsInStateNumber![index]}
                </span>
              </div>
            } />
          ) : (
            ""
          )
        )}
      </Tabs>
    )}
  </>
);

const DocumentTypeSelectElement: React.FC<{
  currentValue: DocumentTypeDTO;
  onDocumentTypeChange: (documentType: DocumentTypeDTO) => void;
}> = (props) => (
  <FormControl>
    <InputLabel id="select-document-type">Document Type</InputLabel>
    <Select
      value={props.currentValue}
      labelId="select-document-type"
      label="Document Type"
      onChange={(event) =>
        props.onDocumentTypeChange(event.target.value as DocumentTypeDTO)
      }
      sx={{ width: "300px", mr: 4 }}
    >
      <MenuItem value={DocumentTypeDTO.Invoice}>Invoice</MenuItem>
      <MenuItem value={DocumentTypeDTO.Receipt}>Receipt</MenuItem>
    </Select>
  </FormControl>
);

const Index: React.FC<{
  documentActions: DocumentActions;
  documentStates: DocumentWorkflowStateDTO[];
  documentStatesTransitions: DocumentWorkflowStateTransitionsDTO[];
  filters: IDocumentFilter;
  docsInStateNumber?: number[];
  export: (exportType: string) => void;
  onStartDateFilterChange: (date: Date) => void;
  onEndDateFilterChange: (date: Date) => void;
  onTypeFilterChange: (documentType: DocumentTypeDTO) => void;
  onStateFilterChange: (documentState: DocumentWorkflowStateDTO) => void;
  onDocumentStateUpdate: (documentState: DocumentWorkflowStateDTO) => void;
  onRefreshChanged: (refreshChanged: Date) => void
}> = (props) => {

  const [refresh, setRefresh] = useState<Date>();

  return (
    <ThemeProvider theme={myTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: "100%",
          justifyContent: "initial",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "100%", display: "flex", gap: 0, flexWrap: "wrap" }}>
            <DocumentTypeSelectElement
              currentValue={props.filters.documentType}
              onDocumentTypeChange={props.onTypeFilterChange}
            />
            <DatePickerElement
              date={props.filters.startDate}
              label="Start date"
              onDateChange={props.onStartDateFilterChange}
            />
            <DatePickerElement
              date={props.filters.endDate}
              label="End date"
              onDateChange={props.onEndDateFilterChange}
            />
            <Button onClick={() => {
              props.onRefreshChanged(new Date());
              setRefresh(new Date())
            }}>
              <RefreshIcon /> Refresh
            </Button>
          </Box>
          <Box sx={{ width: "20%", display: "flex", justifyContent: "end" }}>
            {Object.values(props.documentActions).some(
              (isEnabled) => isEnabled
            ) && (
                <DocumentsActions
                  documentActions={props.documentActions}
                  currentState={props.filters.documentState}
                  documentStatesTransitions={props.documentStatesTransitions}
                  export={props.export}
                  changeState={props.onDocumentStateUpdate}
                  documentStates={props.documentStates}
                />
              )}
          </Box>
        </Box>
        <DocumentStateTabElements
          currentlySelectedState={props.filters.documentState}
          documentStates={props.documentStates}
          onDocumentStateChange={props.onStateFilterChange}
          toBeDisplayed={true}
          docsInStateNumber={props.docsInStateNumber}
        />
      </Box>
    </ThemeProvider>
  )



};

export default Index;
