export class AccountingNoteEntryDTO {
    public id!: string;
    public accountingNoteId!: string;
    public description!: string;
    public value!: number;
    public quantity!: number | null;
    public debitAccount!: string;
    public creditAccount!: string;
    public vatType!: number | null;
    public vatValue!: number | null;
}
