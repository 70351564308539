import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CssBaseline, GlobalStyles, Container } from '@mui/material';
import { registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// Import FilePond styles:
import "filepond/dist/filepond.min.css";
import "./index.css";

import { HttpService } from '../../services/HttpService';
import { CompanyDTO } from '../../domain/Company/CompanyDTO';
import { DocumentDTO } from '../../domain/Document/DocumentDTO';
import { DocumentTypeDTO } from '../../domain/Document/DocumentType';
import { DocumentsList } from './DocumentsList';
import DocumentWorkflowStateDTO from '../../domain/Document/DocumentWorkflowStateDTO';
import { ACTION_TYPES, SelectableDocument, useDocuments } from './DocumentsList/reducer';
import DocumentWorkflowStateTransitionsDTO from '../../domain/Document/DocumentWorkflowStateTransitionDTO';

// Register the plugin:
registerPlugin(FilePondPluginFileValidateType);

type IDocumentFilter = {
  startDate: Date,
  endDate: Date,
  documentType: DocumentTypeDTO,
  documentState: DocumentWorkflowStateDTO | null
};

interface DocumentWorkflowStateCounts {
  [stateName: string]: number; // Use index signature to allow dynamic keys
}




const Documents: React.FC<{}> = () => {
  const [refresh,setRefresh] = useState<Date>();
  const [company, setCompany] = useState<CompanyDTO>();
  const [state, dispatch] = useDocuments();
  const [documentStates, setDocumentStates] = useState<DocumentWorkflowStateDTO[]>([]);
  const [documentStatesTransitions, setDocumentStatesTransitions] = useState<DocumentWorkflowStateTransitionsDTO[]>([]);
  const navigate = useNavigate();
  const { companyCui } = useParams();
  const [filters, setFilters] = useState<IDocumentFilter>({ 
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)), 
    endDate: new Date(), 
    documentState: null, 
    documentType: DocumentTypeDTO.Invoice
  });
  const [docsInStateNumber, setDocsInStateNumber] = useState<number[]>(Array(9).fill(0));


// Fetch the document states
const fetchDocumentStates = async () => {

  const arraysEqual = (a: number[], b: number[]) => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((value, index) => value === b[index]);
  };

  try {
    const { data } = await HttpService.getInstance(navigate).client
      .get<DocumentWorkflowStateCounts>(`/documents/states?companyCui=${companyCui}&documentType=${filters.documentType}`);
    
    const order = [
      "Uploaded",
      "Processing",
      "High Confidence",
      "Low Confidence",
      "Failed",
      "Modified",
      "Approved",
      "Archived",
      "Scrapped"
    ];

    const tempDocumentStates = order.map(stateName => data[stateName] || 0);


    // Compare the new state with the previous one
    if (!arraysEqual(docsInStateNumber, tempDocumentStates)) {
      setDocsInStateNumber(tempDocumentStates);
    }

  } catch (error) {
    console.error('Error fetching document states:', error);
  }
};

useEffect(() => {

  // Fetch document states initially
  fetchDocumentStates();

 
  const intervalId = setInterval(() => {
    fetchDocumentStates(); 
    revalidateDocuments();
  }, 3000); // 3000ms (3 seconds)

  // Cleanup the interval on component unmount
  return () => clearInterval(intervalId);
}, [docsInStateNumber, navigate, companyCui, refresh, filters]); // Add dependencies to ensure effect runs on change

  

  useEffect(() => {
    HttpService.getInstance(navigate).client
    .get<DocumentWorkflowStateDTO[]>(`/workflowStates/documents`)
    .then(({ data }) => {
      setDocumentStates(data.sort((d1, d2) => d1.orderCode - d2.orderCode));
      const documentState = data.length > 0 && data[0];
      if (documentState){
        setFilters({ ...filters, documentState });
      }
    });
    HttpService.getInstance(navigate).client
    .get<DocumentWorkflowStateTransitionsDTO[]>("/workflowStateTransitions")
    .then(({ data }) => {
      if (data)
        setDocumentStatesTransitions(data);
    }); 
  }, []);

  useEffect(() => {
    if (!companyCui) {
      return;
    }
    HttpService.getInstance(navigate).client
      .get<CompanyDTO>(`/Companies/${companyCui}`)
      .then(({ data }) => setCompany(data));
  }, [companyCui, navigate]);

  useEffect(() => {
    if (!company?.id) {
      return;
    }
  
    HttpService.getInstance(navigate).client
      .get<DocumentDTO[]>(`/documents/filtered/${company.id}?start=${filters.startDate.toUTCString()}&end=${filters.endDate.toUTCString()}&workflowStateId=${filters.documentState?.id}`)
      .then(({ data }) => {
        const updatedDocuments = data.reduce((selectableDocuments: SelectableDocument[], document) => {
          if (document.documentType === filters.documentType) {
            // Check if this document is already in the state to preserve its selection status
            const existingDocument = state.documents.find(d => d.document.id === document.id);
            const isSelected = existingDocument ? existingDocument.isSelected : false;
            
            return [...selectableDocuments, { document, isSelected }];
          }
          return selectableDocuments;
        }, []);
  
        // Dispatch updated documents with preserved selection status
        dispatch({ type: ACTION_TYPES.SET_DOCUMENTS, payload: { documents: updatedDocuments } });
      });
  }, [company, filters, navigate, state.shouldRefreshDocuments]);
  

  const onEndDateChange = (date: Date) => {
    const endDate = new Date(date.setUTCHours(0, 0, 0, 0));
    setFilters({ ...filters, endDate });
  };

  const onStartDateChange = (date: Date) => {
    const startDate = new Date(date.setUTCHours(0, 0, 0, 0));
    setFilters({ ...filters, startDate });
  };

  const onDocumentTypeChange = (documentType: DocumentTypeDTO) => {
    setFilters({ ...filters, documentType });
  };

  const onDocumentStateChange = (documentState: DocumentWorkflowStateDTO) => {
    setFilters({ ...filters, documentState });
  }

  const onRefreshChanged = (refreshDate: Date) => {
    setRefresh(refreshDate);
    fetchDocumentStates();
    revalidateDocuments();
  }

  const onDocumentNameClick = (document: SelectableDocument) => dispatch({ type: ACTION_TYPES.OPEN_DOCUMENT, payload: { document }});
  const onAllDocumentsCheckChanged = (allSelected: boolean) => dispatch({ type: ACTION_TYPES.SELECT_ALL, payload: { allSelected } });
  const onDocumentCheckChanged = (document: SelectableDocument) => dispatch({ type: ACTION_TYPES.SELECT_DOCUMENT, payload: { document} });
  const onCloseDocument = () => dispatch({ type: ACTION_TYPES.OPEN_DOCUMENT });
  const revalidateDocuments = () => dispatch({ type: ACTION_TYPES.REFRESH_DOCUMENTS });
  
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      { company?.id &&
        <DocumentsList
          company={company}
          filters={filters}
          documents={state.documents}
          documentStates={documentStates}
          documentStatesTransitions={documentStatesTransitions}
          docsInStateNumber={docsInStateNumber}
          onDocumentNameClick={onDocumentNameClick}
          onAllDocumentsCheckChanged={onAllDocumentsCheckChanged}
          onDocumentCheckChanged={onDocumentCheckChanged}
          navigate={navigate}
          onEndDateChange={onEndDateChange}
          onStartDateChange={onStartDateChange}
          onDocumentTypeChange={onDocumentTypeChange}
          onDocumentStateChange={onDocumentStateChange}
          openedDocument={state.openedDocument} 
          onCloseDocument={onCloseDocument}
          revalidateDocuments={revalidateDocuments}
          onRefreshChanged={onRefreshChanged}
        />}       
      <DocumentsFooterElement />
    </React.Fragment>
  );
};

const DocumentsFooterElement: React.FC<{}> = (props) => (
  <Container
    maxWidth="md"
    component="footer"
    sx={{
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      mt: 6,
    }}
  />
);

export { Documents };
export type { IDocumentFilter };

