import React from "react";
import { Dialog, DialogContent, DialogTitle, Box } from "@mui/material";
import { DocumentDTO } from "../../../domain/Document/DocumentDTO";
import DocumentWorkflowStateDTO from "../../../domain/Document/DocumentWorkflowStateDTO";
import { Invoice } from "./Invoice";
import { PdfViewer } from "./PdfViewer";
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import { NavigateFunction } from "react-router-dom";

const Index: React.FC<{ 
  document: DocumentDTO,
  documentStates: DocumentWorkflowStateDTO[],
  revalidateDocument: () => void,
  navigate: NavigateFunction,
  onClose: () => void
}> = (props) => {
  const isXL = useMediaQuery('(min-width: 1280px)');  // Check if screen is XL or larger

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={true}
      onClose={props.onClose}
      sx={{ 
        '& .MuiDialog-paper': { 
          width: '95vw', 
          maxWidth: 'none', 
          height: '90vh',  // Set dialog height to 90% of viewport height
          display: 'flex', 
          flexDirection: 'column'
        } 
      }}
    >
      <DialogTitle>{props.document.fileName}</DialogTitle>
      <DialogContent
        sx={{ 
          display: "flex", 
          justifyContent: "center", 
          flexDirection: { xs: "column", xl: "row" },
          height: "100%",  // Make the content take up full height of the dialog
          overflowY: "auto"
        }}
      >
        {/* Invoice takes 60% of the width */}
        <Box 
          sx={{ 
            marginRight: { xs: "0", xl: "3px" },
            width: { xs: "100%", xl: "50%" },
            height: "100%",  // Ensure Box takes full height
            overflowY: "auto"
          }}
        >
          <Invoice 
            document={props.document}
            navigate={props.navigate}  
            onClose={props.onClose}
            revalidateDocument={props.revalidateDocument}
          />
        </Box>

        {/* PdfViewer takes 40% of the width, sticky only on XL screens */}
        <Box 
          sx={{ 
            position: isXL ? 'sticky' : 'relative',  // Sticky only for XL screens
            top: isXL ? '20px' : 'unset',            // Stick to the top with 20px offset on XL
            marginTop:{ xs: "20px", xl: "0px" },
            marginLeft: { xs: "0", xl: "3px" },
            width: { xs: "100%", xl: "50%" },
            height: "100%",  // Ensure Box takes full height
            overflowY: "auto"
          }}
        >
          <PdfViewer
            id={props.document.id}
            navigate={props.navigate}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Index;
