import { NavigateFunction } from 'react-router-dom';
import { HttpService } from '../../../../services/HttpService';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import { Button, ThemeProvider, useMediaQuery } from '@mui/material';
import myTheme from '../../../../helpers/themeHelper';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PdfViewerProps = {
    id: string;
    navigate: NavigateFunction;
};



const PdfViewer: React.FC<PdfViewerProps> = (props) => {
    const { id, navigate } = props;
    const [link, setLink] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [renderNavButtons, setRenderNavButtons] = useState<Boolean>(false);

    
    // Set up media queries for responsive scaling
    const isXXL = useMediaQuery('(min-width: 1920px)'); // XXL screen size
    const isXL = useMediaQuery('(min-width: 1540px)');  // XL screen size

    // Determine scale based on screen size
    const scale = isXXL ? 1.4 : isXL ? 1.1 : 1;

    const changePage = (offset: number) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    const previousPage = () => { changePage(-1); }
    const nextPage = () => { changePage(+1); }

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    useEffect(() => {
        HttpService.getInstance(navigate).client.get('/documents/displayPDF', {
            params: { id: id },
            responseType: 'arraybuffer' // Ensure binary data is fetched
        })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch PDF');
                }

                const blob = new Blob([res.data], { type: 'application/pdf' });
                const newLink = URL.createObjectURL(blob);

                setLink(newLink);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    setError('Unauthorized access. Please log in.');
                } else {
                    setError('Failed to load PDF file.');
                }
                console.error(error);
            });
    }, [id, navigate]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <ThemeProvider theme={myTheme}>
            <div>
                {!link ? (
                    <div>Loading PDF...</div>
                ) : (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Document
                                file={link}
                                onLoadSuccess={({ numPages }) => {
                                    setNumPages(numPages);
                                    setRenderNavButtons(true);
                                    setPageNumber(1);
                                }}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} scale={scale} />
                            </Document>
                            </div>
                            {renderNavButtons && (
                                <div className="buttonc" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <Button
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                    >
                                        Previous Page
                                    </Button>
                                    {"  "}
                                    <Button
                                        disabled={pageNumber === numPages}
                                        onClick={nextPage}
                                    >
                                        Next Page
                                    </Button>
                                </div>
                            )}
                        

                    </>
                )}
            </div>
        </ThemeProvider>
    );
};




export { PdfViewer };

