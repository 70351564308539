import * as yup from "yup"

export default yup.object().shape({
    question1: yup.number().required("Trebuie sa completați acest câmp.").nullable(),
    question2: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question3: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question4: yup.number().required("Trebuie sa completați acest câmp.").nullable(),
    question5: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question6: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question7: yup.number().required("Trebuie sa completați acest câmp.").nullable(),
    question8: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question9: yup.number().required("Trebuie sa completați acest câmp.").nullable(),
    question10: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question11: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question12: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question13: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question14: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question15: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question16: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question17: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
    question18: yup.string().required("Trebuie sa completați acest câmp.").nullable(),
})