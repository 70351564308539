import { Box, Button, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Slider, TextField, ThemeProvider, Typography, colors } from '@mui/material'
import React from 'react'

import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import myTheme from '../../helpers/themeHelper';
import myLogo from '../../assets/images/Logo1.png'
import SendIcon from '@mui/icons-material/Send';
import { FeedbackDTO } from './FeedbackDTO';
import ValidationSchema from "./index.validationSchema"
import { HttpService } from '../../services/HttpService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
];

const FeedbackForm: React.FC<{}> = (props) => {

    const navigate = useNavigate();
    const [technicalIssues, setTechnicalIssues] = React.useState<boolean>();

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm<FeedbackDTO>({
        resolver: yupResolver(ValidationSchema),
    });

    const handleOnSubmit = (data: FeedbackDTO) => {
        const jsonString = sessionStorage.getItem('userIdentity');

        if (jsonString) {

            const jsonObject = JSON.parse(jsonString);

            if (jsonObject && jsonObject.id) {
                data.name = jsonObject.name;
                data.phone = jsonObject.phone;
                data.username = jsonObject.username;
                data.email = jsonObject.email;
            }
        }
        HttpService.getInstance(navigate)
            .client.post("/Feedback", data)
            .then((response) => {
                if (response.status == 400) {
                    toast.error(response.data, {
                        position: "top-center",
                        theme: "colored",
                    });
                }
                else {
                    toast.success("Vă mulțumim pentru feedback-ul dvs.!", {
                        position: "top-center",
                        theme: "colored",
                    });
                }

            })
            .catch((error) => {
                toast.error("There was an error and the feedback was not submitted.", {
                    position: "top-center",
                    theme: "colored",
                });
            });
    }


    return (
        <ThemeProvider theme={myTheme}>
            <Container component="main" maxWidth="md">
                <Box component="form" justifyContent="center" onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container spacing={5} justifyContent="center" alignItems="center" direction="column">
                        <Grid item xs={12}>
                            <img src="https://salisterraweb.blob.core.windows.net/assets/Logo_transparent.png" alt="Documatik Logo" />
                        </Grid>
                        <Grid item xs={12}>
                            <h1>
                                Formular de Feedback pentru Utilizatorii Documatik
                            </h1>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>
                                Vă mulțumim că ați ales să testați platforma Documatik pentru automatizarea introducerii datelor din facturi și bonuri fiscale. Apreciem feedback-ul dumneavoastra, deoarece ne ajută să ne îmbunătățim serviciile. Vă rugăm să completați formularul de mai jos.
                            </h3>
                        </Grid>


                    </Grid>
                    <Grid container spacing={5} justifyContent="center" alignItems="left" direction="column">
                        <Grid item xs={12}>
                            <h3 style={{ fontStyle: "bold", color: "#cd2824" }}>A. Experiența generală de utilizare</h3>
                            <FormControl error={Boolean(errors.question1)}>
                                <FormLabel id="question1label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>A.1.</b> Vă rugăm să evaluați experiența generală de utilizare a platformei Documatik, pe o scală de la 1 la 5 (1 – foarte slab, 5 – excelent).
                                </FormLabel>
                                <Box sx={{ width: "auto" }}>
                                    <Controller
                                        name="question1"
                                        control={control}
                                        defaultValue={3} // Set initial value here
                                        rules={{ required: "Trebuie sa completați acest câmp." }} // Validation rule
                                        render={({ field: { onChange, value } }) => (
                                            <Box sx={{ width: "auto" }}>
                                                <Slider
                                                    aria-label="Custom marks"
                                                    value={value}
                                                    step={1}
                                                    min={1}
                                                    max={5}
                                                    marks={marks}
                                                    onChange={(_, val) => onChange(val)}
                                                />
                                            </Box>
                                        )}
                                    />
                                </Box>
                                {errors.question1 && <FormHelperText>{errors.question1.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question2)}>
                                <FormLabel id="question2label" style={{ marginBottom: "10px", width: "850px" }}>
                                    Specificați în ce măsură interfața de încărcare a documentelor și vizualizarea rezultatelor au fost ușor de utilizat.
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question2")}
                                />
                                {errors.question2 && <FormHelperText>{errors.question2.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question3)}>
                                <FormLabel id="question3label" style={{ marginBottom: "10px", width: "850px" }}>
                                    Alte comentarii legate de experiența generală de utilizare a platformei Documatik (ex. am fost foarte mulțumit de ușurința de utilizare a platformei/am întâmpinat dificultăți în găsirea anumitor funcții etc.) :
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question3")}
                                />
                                {errors.question3 && <FormHelperText>{errors.question3.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question4)}>
                                <FormLabel id="question4label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>A.2.</b> Cât de intuitivă ați găsit interfața platformei Documatik, pe o scală de la 1 la 5 (1 – foarte neintuitivă, 5 – foarte intuitivă)?
                                </FormLabel>
                                <Box sx={{ width: "auto" }}>
                                <Controller
                                        name="question4"
                                        control={control}
                                        defaultValue={3} // Set initial value here
                                        rules={{ required: "Trebuie sa completați acest câmp." }} // Validation rule
                                        render={({ field: { onChange, value } }) => (
                                            <Box sx={{ width: "auto" }}>
                                                <Slider
                                                    aria-label="Custom marks"
                                                    value={value}
                                                    step={1}
                                                    min={1}
                                                    max={5}
                                                    marks={marks}
                                                    onChange={(_, val) => onChange(val)}
                                                />
                                            </Box>
                                        )}
                                    />
                                </Box>
                                {errors.question4 && <FormHelperText>{errors.question4.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question5)}>
                                <FormLabel id="question5label" style={{ marginBottom: "10px", width: "850px" }}>
                                    Vă rugăm să specificați ce funcționalități au fost ușor de folosit și care au fost mai dificil de înțeles (ex. găsirea butonului de procesare a documentelor a fost intuitivă, dar am întâmpinat dificultăți în navigarea către secțiunea de setări)
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question5")}
                                />
                                {errors.question5 && <FormHelperText>{errors.question5.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question6)}>
                                <FormLabel id="question6label" style={{ marginBottom: "10px", width: "850px" }}>
                                    Cât de utile sunt informațiile și ghidurile disponibile?

                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question6")}
                                />
                                {errors.question6 && <FormHelperText>{errors.question6.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question7)}>
                                <FormLabel id="question7label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>A.3.</b> Vă rugăm să evaluați acuratețea extragerii datelor din documentele încărcate, pe o scară de la 1 la 5 (1 – foarte nemulțumit, 5 – foarte mulțumit).
                                </FormLabel>
                                <Box sx={{ width: "auto" }}>
                                <Controller
                                        name="question7"
                                        control={control}
                                        defaultValue={3} // Set initial value here
                                        rules={{ required: "Trebuie sa completați acest câmp." }} // Validation rule
                                        render={({ field: { onChange, value } }) => (
                                            <Box sx={{ width: "auto" }}>
                                                <Slider
                                                    aria-label="Custom marks"
                                                    value={value}
                                                    step={1}
                                                    min={1}
                                                    max={5}
                                                    marks={marks}
                                                    onChange={(_, val) => onChange(val)}
                                                />
                                            </Box>
                                        )}
                                    />
                                </Box>
                                {errors.question7 && <FormHelperText>{errors.question7.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question8)}>
                                <FormLabel id="question8label" style={{ marginBottom: "10px", width: "850px" }}>
                                    Dacă au existat erori de extragere, vă rugăm să oferiți exemple concrete.
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question8")}
                                />
                                {errors.question8 && <FormHelperText>{errors.question8.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question9)}>
                                <FormLabel id="question9label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>A.4.</b> Cum evaluați viteza de procesare a documentelor încărcate pe platforma Documatik, pe o scară de la 1 la 5 (1 – foarte lentă, 5 – foarte rapidă)?

                                </FormLabel>
                                <Box sx={{ width: "auto" }}>
                                <Controller
                                        name="question9"
                                        control={control}
                                        defaultValue={3} // Set initial value here
                                        rules={{ required: "Trebuie sa completați acest câmp." }} // Validation rule
                                        render={({ field: { onChange, value } }) => (
                                            <Box sx={{ width: "auto" }}>
                                                <Slider
                                                    aria-label="Custom marks"
                                                    value={value}
                                                    step={1}
                                                    min={1}
                                                    max={5}
                                                    marks={marks}
                                                    onChange={(_, val) => onChange(val)}
                                                />
                                            </Box>
                                        )}
                                    />
                                </Box>
                                {errors.question9 && <FormHelperText>{errors.question9.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question10)}>
                                <FormLabel id="question10label" style={{ marginBottom: "10px", width: "850px" }}>
                                    Dacă ați observat întârzieri, vă rugăm să le descrieți.
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question10")}
                                />
                                {errors.question10 && <FormHelperText>{errors.question10.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{ fontStyle: "bold", color: "#cd2824" }}>B. Probleme tehnice apărute în timpul utilizării</h3>
                            <FormControl error={Boolean(errors.question11)}>
                                <FormLabel id="question11label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>B.1.</b> Câte incidente tehnice (ex. erori, întârzieri, blocaje) ați întâmpinat în timpul utilizării platformei Documatik?
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question11")}
                                />
                                {errors.question11 && <FormHelperText>{errors.question11.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question12)}>
                                <FormLabel id="question12label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>B.2.</b> Vă rugăm să specificați tipul și frecvența incidentelor.: (ex. ”Interfața nu încarcă anumite documente PDF.”):
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question12")}
                                />
                                {errors.question12 && <FormHelperText>{errors.question12.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question13)}>
                                <FormLabel id="question10label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>B.3.</b> Dacă a fost necesară intervenția suportului tehnic, vă rugăm să descrieți problema, cum a fost rezolvată și dacă a fost necesară escaladarea acesteia. De asemenea, menționați dacă au existat cazuri în care problema nu a fost soluționată în timp util.
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question13")}
                                />
                                {errors.question13 && <FormHelperText>{errors.question13.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{ fontStyle: "bold", color: "#cd2824" }}>C. Dezvoltări viitoare/îmbunătățiri*</h3>
                            <FormControl error={Boolean(errors.question14)}>
                                <FormLabel id="question1label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>C.1.</b> Ce funcționalități noi credeți că ar aduce cel mai mare beneficiu platformei Documatik? Vă rugăm să oferiți un exemplu concret despre cum ar trebui să funcționeze noua funcționalitate și ce problemă ar rezolva.
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question14")}
                                />
                                {errors.question14 && <FormHelperText>{errors.question14.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={Boolean(errors.question15)}>
                                <FormLabel id="question10label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>C.2.</b> Aveți sugestii pentru îmbunătățirea funcționalităților actuale ale platformei Documatik? Vă rugăm să specificați o funcționalitate existentă și cum ar trebui optimizată pentru a aduce un beneficiu suplimentar. De exemplu, cum ar trebui să fie structurat un raport personalizat?
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question15")}
                                />
                                {errors.question15 && <FormHelperText>{errors.question15.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{ fontStyle: "bold", color: "#cd2824" }}>D. Alte aspecte (care nu sunt cuprinse in punctele A – C de mai sus)</h3>
                            <FormControl error={Boolean(errors.question1)}>
                                <FormLabel id="question16label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>D.1.</b> Comentarii:
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question16")}
                                />
                                {errors.question16 && <FormHelperText>{errors.question16.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{ fontStyle: "bold", color: "#cd2824" }}>E. Recomandări/colaborare viitoare</h3>
                            <FormControl error={Boolean(errors.question17)}>
                                <FormLabel id="question1label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>E.1.</b> Ați recomanda Documatik altor companii sau parteneri? (Da/Nu)
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question17")}
                                />
                                {errors.question17 && <FormHelperText>{errors.question17.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>

                            <FormControl error={Boolean(errors.question18)}>
                                <FormLabel id="question1label" style={{ marginBottom: "10px", width: "850px" }}>
                                    <b style={{ fontSize: "17px", fontStyle: "bold", color: "#cd2824" }}>E.2.</b> Care sunt posibilele modalități de colaborare viitoare între compania dumneavoastră și Documatik? (ex. extinderea utilizării platformei, integrarea cu alte sisteme, dezvoltarea unor funcționalități personalizate, colaborare pentru testarea noilor funcționalități beta etc.)
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="..."
                                    multiline
                                    maxRows={6}
                                    {...register("question18")}
                                />
                                {errors.question18 && <FormHelperText>{errors.question18.message}</FormHelperText>}
                            </FormControl>
                        </Grid>

                    </Grid>
                    <div style={{ height: "2vh" }}></div>
                    <div style={{ justifyContent: "center", alignItems: "left", width: '100%', display: 'flex' }}>
                        <Button
                            startIcon={<SendIcon />}
                            variant="contained"
                            type="submit"
                            component="button"
                        >
                            Trimite Feedback
                        </Button>
                    </div>

                    <div style={{ height: "10vh" }}></div>
                </Box>



            </Container>
        </ThemeProvider>
    )
}

export default FeedbackForm;